import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { AppWithRouter } from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
const params = new URL(window.location.href).searchParams;
const cId = params.get("cid"); // reveiw surge consumer id
const seId = params.get("seid"); //old survey id
const type = params.get("type"); //type (EM=email or SM=sms)
const clientId = params.get("clientid"); // open survey reminder
const osr = params.get("osr"); // open survey reminder
const pathName = window.location.pathname;

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppWithRouter
        urlData={{
          consumerId: cId,
          surveyEmailId: seId,
          type: type,
          pathName: pathName,
          osr: osr,
          clientId: clientId,
        }}
      />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
