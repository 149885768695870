import React, { Component } from "react";
import $ from "jquery";
import axios from "axios";
import surveyPagination from "../assets/images/survey-pagination-2.png";
import edmundsLogo from "../assets/images/edmunds-logo.png";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import { isGuid } from "../utils/utils";

export default class EdmundsPublishSv extends Component {
  constructor(props) {
    super(props);

    window.scrollTo(0, 0);

    this.state = {
      cId: this.props.urlData.consumerId,
      seId: this.props.urlData.surveyEmailId,
      type: this.props.urlData.type,
      clientId: this.props.urlData.clientId,
      ipAddress: this.props.urlData.ipAddress,
      navigate: this.props.urlData.navigate,
      customerFirstName: this.props.urlData.customerFirstName,
      customerEmail: this.props.urlData.customerEmail,
      comments: this.props.urlData.comments,
      handler: this.props.urlData.handler,
      handlerSurveyInfo: this.props.urlData.handlerSurveyInfo,
      smartGmailSurvey: this.props.urlData.smartGmailSurvey,
      recommend: true,
      workQuality: true,
      workDoneOnTime: true,
      honorCommit: "yes",
      accuratePriceQuote: true,
      fairPrice: true,
      charactersCount: 75,
      title: "",
      starRating: 5,
    };

    this.handleRating = (rate) => {
      this.setState({
        starRating: rate,
      });
    };

    this.handleChkPurchaseChange = (e) => {
      var chkId = e.target.id;
      var purchase = false;

      if (chkId === "radioYes") purchase = true;
      else purchase = false;

      this.setState({
        purchaseVehicle: purchase,
      });
    };

    this.handleChkRecommendChange = (e) => {
      var chkId = e.target.id;
      var recommendDealer = false;

      if (chkId === "radioYes") recommendDealer = true;
      else recommendDealer = false;

      this.setState({
        recommend: recommendDealer,
      });
    };

    this.handleChkWorkQualityChange = (e) => {
      var chkId = e.target.id;
      var WorkQuality = false;

      if (chkId === "radioYes") WorkQuality = true;
      else WorkQuality = false;

      this.setState({
        workQuality: WorkQuality,
      });
    };

    this.handleChkWorkDoneOnTimeChange = (e) => {
      var chkId = e.target.id;
      var WorkDoneOnTime = false;

      if (chkId === "radioYes") WorkDoneOnTime = true;
      else WorkDoneOnTime = false;

      this.setState({
        workDoneOnTime: WorkDoneOnTime,
      });
    };

    this.handleChkAccuratePriceQuoteChange = (e) => {
      var chkId = e.target.id;
      var AccuratePriceQuote = false;

      if (chkId === "radioYes") AccuratePriceQuote = true;
      else AccuratePriceQuote = false;

      this.setState({
        accuratePriceQuote: AccuratePriceQuote,
      });
    };

    this.handleChkFairPriceChange = (e) => {
      var chkId = e.target.id;
      var FairPrice = false;

      if (chkId === "radioYes") FairPrice = true;
      else FairPrice = false;

      this.setState({
        fairPrice: FairPrice,
      });
    };

    this.handleChkHonorCommitChange = (e) => {
      var chkId = e.target.id;
      var HonorCommit = false;

      if (chkId === "radioYes") HonorCommit = "yes";
      else if (chkId === "radioNo") HonorCommit = "no";
      else HonorCommit = "not_applicable";

      this.setState({
        honorCommit: HonorCommit,
      });
    };

    this.handleEdmundsTitleChange = (e) => {
      const edmundsTitle = e.target.value;
      var count = 75 - e.target.value.length;
      this.setState({
        title: edmundsTitle,
        charactersCount: count,
      });
    };

    this.handleEdmundsCommentsChange = (e) => {
      const edmundsComments = e.target.value;
      this.setState({
        comments: edmundsComments,
      });
    };

    this.handleEdmundsEmailChange = (e) => {
      const edmundsEmail = e.target.value;
      this.setState({
        customerEmail: edmundsEmail,
      });
    };

    this.handleReviewDisplayName = (e) => {
      const displayName = e.target.value;
      this.setState({
        customerFirstName: displayName,
      });
    };

    this.clickEdmundsSubmit = (e) => {
      e.preventDefault();
      this.SubmitEdmunds();
    };

    this.clickEdmundsSkip = (e) => {
      e.preventDefault();
      this.SubmitEdmundsSkip();
    };
  }

  componentDidMount() {
    window.onpopstate = () => {
      this.state.navigate(0);
    };

    if (
      this.state.customerFirstName === "" ||
      this.state.customerEmail === "" ||
      this.state.comments === ""
    ) {
      this.GetSurveyInfo();
      this.GetGeoLocationData();
    }
  }

  async GetGeoLocationData() {
    /*await axios
      .get("https://geolocation-db.com/json/")
      .then((res) => {
        const geolocationData = res.data;
        this.setState({ ipAddress: geolocationData.IPv4 });
      })
      .catch((error) => {
        console.log(error);
      }); */
  }

  async GetSurveyInfo() {
    const host = process.env.REACT_APP_API_BASE_URL;
    const consumerId = this.props.urlData.consumerId;
    const surveyEmailId = this.props.urlData.surveyEmailId;

    await axios
      .get(
        `${host}/surveyinfo?ConsumerId=${consumerId}&SurveyEmailId=${surveyEmailId}`,
        {
          headers: {
            "DAS-Guest-Subscription-Key":
              process.env.REACT_APP_API_SUBSCRIPTION_KEY, //the guest subscription key
          },
        }
      )
      .then((res) => {
        const surveyResponseData = res.data;

        this.setState({
          customerFirstName: surveyResponseData.customerFirstName,
          customerEmail: surveyResponseData.customerEmail,
          dealerName: surveyResponseData.dealerName,
          transactionType: surveyResponseData.transactionType,
          smartGmailSurvey: surveyResponseData.smartGmailSurvey,
        });

        this.state.handlerSurveyInfo(
          surveyResponseData.customerFirstName,
          surveyResponseData.customerEmail,
          surveyResponseData.dealerName,
          surveyResponseData.transactionType,
          surveyResponseData.surveyThirdPartySiteToPublish,
          surveyResponseData.surveyThankYouPageText,
          surveyResponseData.surveyNegativeThankYouPageText,
          surveyResponseData.smartGmailSurvey
        );

        this.GetSurveyResponseInfo();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async GetSurveyResponseInfo() {
    const host = process.env.REACT_APP_API_BASE_URL;
    const consumerId = this.props.urlData.consumerId;
    const surveyEmailId = this.props.urlData.surveyEmailId;

    await axios
      .get(
        `${host}/surveyresponseinfo?ConsumerId=${consumerId}&SurveyEmailId=${surveyEmailId}`,
        {
          headers: {
            "DAS-Guest-Subscription-Key":
              process.env.REACT_APP_API_SUBSCRIPTION_KEY, //the guest subscription key
          },
        }
      )
      .then((res) => {
        const surveyResponseInfoData = res.data;

        this.setState({
          comments: surveyResponseInfoData.surveyResponseText,
        });

        this.state.handler(surveyResponseInfoData.surveyResponseText);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async SubmitEdmunds() {
    $("#loaderSpinnerEdmundsService").show();
    const host = process.env.REACT_APP_API_BASE_URL;
    const consumerId = this.props.urlData.consumerId;
    const surveyEmailId = this.props.urlData.surveyEmailId;
    const type = this.props.urlData.type;
    const starRating = this.state.starRating;
    const reviewTitle = this.state.title;
    const review = this.state.comments;
    const emailAddress = this.state.customerEmail;
    const reviewDisplayName = this.state.customerFirstName;
    const clientId = this.props.urlData.clientId;

    if (clientId === null) {
      if (isGuid(consumerId) && isGuid(surveyEmailId)) {
        if (
          consumerId !== null &&
          surveyEmailId !== null &&
          type !== null &&
          type !== "" &&
          starRating !== 0 &&
          reviewTitle.trim() !== "" &&
          review.trim() !== "" &&
          reviewDisplayName.trim() !== "" &&
          emailAddress.trim() !== ""
        ) {
          //Remove classes
          $("#starRatingSelector").removeClass("is-invalid");
          $("#txtReviewTitle").removeClass("is-invalid");
          $("#txtYourReview").removeClass("is-invalid");
          $("#txtEmailAddress").removeClass("is-invalid");
          $("#txtReviewDisplayName").removeClass("is-invalid");

          //Request body
          const body = {
            consumerId: consumerId,
            surveyEmailId: surveyEmailId,
            reviewTitle: this.state.title,
            review: this.state.comments,
            locationDescription: "",
            email: this.state.customerEmail,
            username: this.state.customerFirstName,
            state: "",
            starRating: this.state.starRating,
            ipAddress: this.state.ipAddress,
            surveyOpened: type,
            recommend: this.state.recommend,
            workQuality: this.state.workQuality,
            workDoneOnTime: this.state.workDoneOnTime,
            honorCommit: this.state.honorCommit,
            accuratePriceQuote: this.state.accuratePriceQuote,
            fairPrice: this.state.fairPrice,
          };
          const headers = {
            "DAS-Guest-Subscription-Key":
              process.env.REACT_APP_API_SUBSCRIPTION_KEY,
          };

          await axios
            .post(`${host}/publishedmunds`, body, { headers })
            .then((res) => {
              this.state.navigate({
                pathname: "/thankyou",
                search:
                  "?cid=" +
                  this.state.cId +
                  "&seid=" +
                  this.state.seId +
                  "&type=" +
                  this.state.type +
                  "",
              });
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          //Validate
          if (starRating === 0) {
            $("#starRatingSelector").addClass("is-invalid");
          } else {
            $("#starRatingSelector").removeClass("is-invalid");
          }
          if (reviewTitle.trim() === "") {
            $("#txtReviewTitle").addClass("is-invalid");
          } else {
            $("#txtReviewTitle").removeClass("is-invalid");
          }
          if (review.trim() === "") {
            $("#txtYourReview").addClass("is-invalid");
          } else {
            $("#txtYourReview").removeClass("is-invalid");
          }
          if (reviewDisplayName.trim() === "") {
            $("#txtReviewDisplayName").addClass("is-invalid");
          } else {
            $("#txtReviewDisplayName").removeClass("is-invalid");
          }
          if (emailAddress.trim() === "") {
            $("#txtEmailAddress").addClass("is-invalid");
          } else {
            $("#txtEmailAddress").removeClass("is-invalid");
          }
        }
      }
    } else {
      this.state.navigate({
        pathname: "/thankyou",
        search:
          "?cid=" +
          this.state.cId +
          "&seid=" +
          this.state.seId +
          "&type=" +
          this.state.type +
          "",
      });
    }

    $("#loaderSpinnerEdmundsService").hide();
  }

  async SubmitEdmundsSkip() {
    $("#loaderSpinnerEdmundsService").show();
    const host = process.env.REACT_APP_API_BASE_URL;
    const consumerId = this.props.urlData.consumerId;
    const surveyEmailId = this.props.urlData.surveyEmailId;
    const clientId = this.props.urlData.clientId;

    if (clientId === null) {
      if (isGuid(consumerId) && isGuid(surveyEmailId)) {
        const body = {};
        const headers = {
          "DAS-Guest-Subscription-Key":
            process.env.REACT_APP_API_SUBSCRIPTION_KEY,
        };

        await axios
          .post(
            `${host}/publishedmundsskip?ConsumerId=${consumerId}&SurveyEmailId=${surveyEmailId}`,
            body,
            { headers }
          )
          .then((res) => {
            this.state.navigate({
              pathname: "/thankyou",
              search:
                "?cid=" +
                this.state.cId +
                "&seid=" +
                this.state.seId +
                "&type=" +
                this.state.type +
                "",
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      this.state.navigate({
        pathname: "/thankyou",
        search:
          "?cid=" +
          this.state.cId +
          "&seid=" +
          this.state.seId +
          "&type=" +
          this.state.type +
          "",
      });
    }

    $("#loaderSpinnerEdmundsService").hide();
  }

  render() {
    return (
      <div className="survey-menu-container">
        <div id="loaderSpinnerEdmundsService" className="loader"></div>
        <table width="100%" height="70px">
          <tbody>
            <tr>
              <td
                style={{
                  verticalAlign: "bottom",
                }}
              >
                <img src={edmundsLogo} alt="Edmunds Form Page"></img>
              </td>
              <td>
                <span className="survey-pagination">
                  <img
                    src={surveyPagination}
                    alt="Progress bar.  Page 2 of 3."
                  ></img>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <hr
          style={{
            height: "1px",
            backgroundColor: "darkblue",
            border: "none",
          }}
        ></hr>
        <div className="survey-main-description">
          <span>
            <p>
              Thank you for taking a moment to share your experience with us.
              Your feedback helps us continue to improve the experience
              customers have with us. We would like permission to share your
              comments with others on Edmunds.com. Simply complete the form
              below to submit your review. You will receive an email to confirm
              your review before it is published.
            </p>
          </span>
          <div className="control-group">
            <p
              style={{
                fontWeight: "bold",
                fontSize: "13px",
              }}
            >
              Overall Rating&nbsp;
              <font color="red">*</font>&nbsp;
            </p>
            <div className="edmunds-question-div">
              <Rating
                id="starRatingSelector"
                onClick={this.handleRating}
                initialValue="5"
                size="30"
              />
            </div>
          </div>
          <div className="control-group">
            <div className="edmunds-question-div">
              <Form.Group className="mb-3" controlId="txtReviewTitle">
                <Form.Label
                  style={{
                    fontWeight: "bold",
                    fontSize: "13px",
                  }}
                >
                  Review Title: <font color="red">*</font>&nbsp;
                </Form.Label>
                <Form.Control
                  style={{
                    fontWeight: "normal",
                    fontSize: "13px",
                  }}
                  maxLength={75}
                  onChange={this.handleEdmundsTitleChange}
                />
                <Form.Text
                  className="text-muted"
                  style={{
                    fontStyle: "italic",
                  }}
                >
                  {this.state.charactersCount} Characters
                </Form.Text>
              </Form.Group>
            </div>
          </div>
          <div className="control-group">
            <div className="experience-text-div">
              <Form.Group className="mb-3" controlId="txtYourReview">
                <Form.Label
                  style={{
                    fontWeight: "bold",
                    fontSize: "13px",
                  }}
                >
                  Your Review: <font color="red">*</font>&nbsp;
                </Form.Label>
                <Form.Control
                  value={this.state.comments}
                  size="sm"
                  as="textarea"
                  rows={3}
                  style={{
                    fontWeight: "normal",
                    fontSize: "13px",
                  }}
                  onChange={this.handleEdmundsCommentsChange}
                />
                <Form.Text
                  className="text-muted"
                  style={{
                    fontStyle: "italic",
                  }}
                >
                  Please do not write a review if you have been offered any
                  payment or other incentive to do so, are employed by the
                  dealership, or have a personal relationship with any employee.
                  Do not type in ALL CAPS. No links, no profanity or
                  name-calling, no soliciting, no personal information such as
                  email addresses or phone numbers.
                </Form.Text>
              </Form.Group>
            </div>
          </div>
          <div className="control-group">
            <div className="edmunds-question-div">
              <Form.Group className="mb-3" controlId="formRecommend">
                <Form.Label>
                  Would you recommend this dealership to friends and family?{" "}
                  <font color="red">*</font>&nbsp;
                </Form.Label>
                <Form.Check
                  checked={this.state.recommend === true}
                  type="radio"
                  name="groupRecommend"
                  id="radioYes"
                  label="Yes"
                  onChange={this.handleChkRecommendChange}
                />
                <Form.Check
                  checked={this.state.recommend === false}
                  type="radio"
                  name="groupRecommend"
                  id="radioNo"
                  label="No"
                  onChange={this.handleChkRecommendChange}
                />
              </Form.Group>
            </div>
          </div>
          <div className="control-group">
            <div className="edmunds-question-div">
              <Form.Group className="mb-3" controlId="formWorkQuality">
                <Form.Label>
                  Were you satisfied with the quality of work performed on your
                  vehicle? <font color="red">*</font>&nbsp;
                </Form.Label>
                <Form.Check
                  checked={this.state.workQuality === true}
                  type="radio"
                  name="groupWorkQuality"
                  id="radioYes"
                  label="Yes"
                  onChange={this.handleChkWorkQualityChange}
                />
                <Form.Check
                  checked={this.state.workQuality === false}
                  type="radio"
                  name="groupWorkQuality"
                  id="radioNo"
                  label="No"
                  onChange={this.handleChkWorkQualityChange}
                />
              </Form.Group>
            </div>
          </div>
          <div className="control-group">
            <div className="edmunds-question-div">
              <Form.Group className="mb-3" controlId="formWorkDoneOnTime">
                <Form.Label>
                  Did the dealership complete the work within the timeframe
                  promised? <font color="red">*</font>&nbsp;
                </Form.Label>
                <Form.Check
                  checked={this.state.workDoneOnTime === true}
                  type="radio"
                  name="groupWorkDoneOnTime"
                  id="radioYes"
                  label="Yes"
                  onChange={this.handleChkWorkDoneOnTimeChange}
                />
                <Form.Check
                  checked={this.state.workDoneOnTime === false}
                  type="radio"
                  name="groupWorkDoneOnTime"
                  id="radioNo"
                  label="No"
                  onChange={this.handleChkWorkDoneOnTimeChange}
                />
              </Form.Group>
            </div>
          </div>
          <div className="control-group">
            <div className="edmunds-question-div">
              <Form.Group className="mb-3" controlId="formHonorCommit">
                <Form.Label>
                  Did the dealer honor all commitments made?{" "}
                  <font color="red">*</font>&nbsp;
                </Form.Label>
                <Form.Check
                  checked={this.state.honorCommit === "yes"}
                  type="radio"
                  name="groupHonorCommit"
                  id="radioYes"
                  label="Yes"
                  onChange={this.handleChkHonorCommitChange}
                />
                <Form.Check
                  checked={this.state.honorCommit === "no"}
                  type="radio"
                  name="groupHonorCommit"
                  id="radioNo"
                  label="No"
                  onChange={this.handleChkHonorCommitChange}
                />
                <Form.Check
                  checked={this.state.honorCommit === "not_applicable"}
                  type="radio"
                  name="groupHonorCommit"
                  id="radioNotApplicable"
                  label="Not Applicable"
                  onChange={this.handleChkHonorCommitChange}
                />
              </Form.Group>
            </div>
          </div>
          <div className="control-group">
            <div className="edmunds-question-div">
              <Form.Group className="mb-3" controlId="formAccuratePriceQuote">
                <Form.Label>
                  Did the dealership provide you with an accurate quote of the
                  work to be performed? <font color="red">*</font>&nbsp;
                </Form.Label>
                <Form.Check
                  checked={this.state.accuratePriceQuote === true}
                  type="radio"
                  name="groupAccuratePriceQuote"
                  id="radioYes"
                  label="Yes"
                  onChange={this.handleChkAccuratePriceQuoteChange}
                />
                <Form.Check
                  checked={this.state.accuratePriceQuote === false}
                  type="radio"
                  name="groupAccuratePriceQuote"
                  id="radioNo"
                  label="No"
                  onChange={this.handleChkAccuratePriceQuoteChange}
                />
              </Form.Group>
            </div>
          </div>
          <div className="control-group">
            <div className="edmunds-question-div">
              <Form.Group className="mb-3" controlId="formFairPrice">
                <Form.Label>
                  Do you feel the price paid for service(s) was fair and
                  appropriate? <font color="red">*</font>&nbsp;
                </Form.Label>
                <Form.Check
                  checked={this.state.fairPrice === true}
                  type="radio"
                  name="groupFairPrice"
                  id="radioYes"
                  label="Yes"
                  onChange={this.handleChkFairPriceChange}
                />
                <Form.Check
                  checked={this.state.fairPrice === false}
                  type="radio"
                  name="groupFairPrice"
                  id="radioNo"
                  label="No"
                  onChange={this.handleChkFairPriceChange}
                />
              </Form.Group>
            </div>
          </div>
          <div className="control-group">
            <div className="edmunds-question-div">
              <Form.Group className="mb-3" controlId="txtEmailAddress">
                <Form.Label
                  style={{
                    fontWeight: "bold",
                    fontSize: "13px",
                  }}
                >
                  Email Address: <font color="red">*</font>&nbsp;
                </Form.Label>
                <Form.Control
                  maxLength={75}
                  value={this.state.customerEmail}
                  type="email"
                  style={{
                    fontWeight: "normal",
                    fontSize: "13px",
                  }}
                  onChange={this.handleEdmundsEmailChange}
                />
              </Form.Group>
            </div>
          </div>
          <div className="control-group">
            <div className="edmunds-question-div">
              <Form.Group className="mb-3" controlId="txtReviewDisplayName">
                <Form.Label
                  style={{
                    fontWeight: "bold",
                    fontSize: "13px",
                  }}
                >
                  Review Display Name: <font color="red">*</font>&nbsp;
                </Form.Label>
                <Form.Control
                  maxLength={75}
                  value={this.state.customerFirstName}
                  style={{
                    fontWeight: "normal",
                    fontSize: "13px",
                  }}
                  onChange={this.handleReviewDisplayName}
                />
              </Form.Group>
            </div>
          </div>
          <div
            style={{
              textAlign: "left",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Link className="btn btn-primary" onClick={this.clickEdmundsSubmit}>
              Submit
            </Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Link className="btn btn-danger" onClick={this.clickEdmundsSkip}>
              Skip
            </Link>
          </div>
          <hr
            style={{
              height: "1px",
              backgroundColor: "darkblue",
              border: "none",
              margin: "10px",
            }}
          ></hr>
          <div
            style={{
              height: "10px",
            }}
          >
            <table width="100%">
              <tbody>
                <tr>
                  <td>
                    <p
                      style={{
                        marginLeft: "15px",
                        fontWeight: "normal",
                        fontSize: "11px",
                      }}
                    >
                      {" "}
                      By submitting my review, I am agreeing to the{" "}
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.edmunds.com/about/visitor-agreement.html"
                        id="lnkMembership"
                      >
                        Edmunds Visitor Agreement{" "}
                      </a>
                    </p>
                  </td>
                  <td
                    style={{
                      textAlign: "right",
                      verticalAlign: "top",
                    }}
                  >
                    <span>
                      <font color="red">*</font>&nbsp;Required
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
