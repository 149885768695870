import "./App.css";
import axios from "axios";
import $ from "jquery";
import React, { Component } from "react";
import loadingGif from "./assets/images/loading.gif";
import HeaderLogo from "./components/headerLogo";
import SurveyMain from "./components/surveyMain";
import DonePage from "./components/donePage";
import ThankYouPageNegative from "./components/thankYouPageNegative";
import ThankYouPagePositive from "./components/thankYouPagePositive";
import SurveyNotFound from "./components/surveyNotFound";
import EdmundsPublish from "./components/edmundsPublish";
import EdmundsPublishSv from "./components/edmundsPublishSv";
import CarsPublish from "./components/carsPublish";
import Unsubscribe from "./components/unsubscribe";
import { Route, Routes, useNavigate, Navigate } from "react-router-dom";
import { isGuid } from "./utils/utils.js";

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "React",
      navigate: this.props.urlData.redirect.navigate,
      cId: this.props.urlData.consumerId,
      seId: this.props.urlData.surveyEmailId,
      type: this.props.urlData.type,
      pathName: this.props.urlData.pathName,
      osr: this.props.urlData.osr,
      clientId: this.props.urlData.clientId,
      transactionType: "",
      ipAddress: "",
      customerFirstName: "",
      customerEmail: "",
      dealerName: "",
      comments: "",
      reviewSites: [],
      reviewSitesNegative: [],
      thirdPartySiteToPublish: "",
      overallStarRating: 5,
      surveyThankYouPageText: "",
      surveyNegativeThankYouPageText: "",
      smartGmailSurvey: false,
      hasGoogle: false,
    };
  }

  handleChildChange = (commentsChild) => {
    this.setState({ comments: commentsChild });
  };

  handleOverallStarRatingChildChange = (starRatingChild) => {
    this.setState({ overallStarRating: starRatingChild });
  };

  handleSurveyInfo = (
    firstNameChild,
    emailChild,
    dealerNameChild,
    transactionTypeChild,
    thirdPartySiteToPublishChild,
    surveyThankYouPageTextChild,
    surveyNegativeThankYouPageTextChild,
    smartGmailSurveyChild
  ) => {
    this.setState({
      customerFirstName: firstNameChild,
      customerEmail: emailChild,
      dealerName: dealerNameChild,
      transactionType: transactionTypeChild,
      thirdPartySiteToPublish: thirdPartySiteToPublishChild,
      surveyThankYouPageText: surveyThankYouPageTextChild,
      surveyNegativeThankYouPageText: surveyNegativeThankYouPageTextChild,
      smartGmailSurvey: smartGmailSurveyChild,
    });
  };

  componentDidMount() {
    $("#loaderSpinnerMain").show();
    this.GetGeoLocationData();
    this.GetSurveyResponseInfo(this.state.navigate);
  }

  componentDidUpdate() {
    $("#loaderSpinnerMain").hide();
  }

  async GetGeoLocationData() {
    /*await axios
      .get("https://geolocation-db.com/json/")
      .then((res) => {
        const geolocationData = res.data;
        this.setState({ ipAddress: geolocationData.IPv4 });
      })
      .catch((error) => {
        console.log(error);
      });
      */
  }

  async GetSurveyResponseInfo(navigate) {
    const host = process.env.REACT_APP_API_BASE_URL;
    const consumerId = this.props.urlData.consumerId;
    const surveyEmailId = this.props.urlData.surveyEmailId;
    const type = this.props.urlData.type;
    const pathName = this.props.urlData.pathName;
    const clientId = this.props.urlData.clientId;

    await axios
      .get(
        `${host}/surveyresponseinfo?ConsumerId=${consumerId}&SurveyEmailId=${surveyEmailId}&ClientId=${clientId}`,
        {
          headers: {
            "DAS-Guest-Subscription-Key":
              process.env.REACT_APP_API_SUBSCRIPTION_KEY, //the guest subscription key
          },
        }
      )
      .then((res) => {
        const surveyResponseData = res.data;

        if (!surveyResponseData.surveyFound && clientId === null) {
          return navigate("/error");
        }

        if (this.state.smartGmailSurvey === false) {
          this.setState({
            smartGmailSurvey: surveyResponseData.smartGmailSurvey,
          });
        }

        this.GetDealerSites();

        //Redirect to the corresponding page.
        this.RedirectToPage(
          navigate,
          consumerId,
          surveyEmailId,
          type,
          surveyResponseData,
          pathName
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async GetDealerSites() {
    const host = process.env.REACT_APP_API_BASE_URL;
    const consumerId = this.props.urlData.consumerId;
    const surveyEmailId = this.props.urlData.surveyEmailId;
    const clientId = this.props.urlData.clientId;

    if (isGuid(consumerId) && isGuid(surveyEmailId)) {
      const headers = {
        "DAS-Guest-Subscription-Key":
          process.env.REACT_APP_API_SUBSCRIPTION_KEY,
      };

      await axios
        .get(
          `${host}/thankyoupagesites?ConsumerId=${consumerId}&SurveyEmailId=${surveyEmailId}&ClientId=${clientId}`,
          { headers }
        )
        .then((res) => {
          const dealerData = res.data;

          if (Array.isArray(dealerData) && dealerData.length) {
            const googlesite = dealerData.filter(function (el) {
              return el.name === "Google";
            });

            if (googlesite.length > 0) {
              this.setState({
                hasGoogle: true,
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  async RedirectToPage(
    navigate,
    consumerId,
    surveyEmailId,
    type,
    surveyResponseData,
    pathName
  ) {
    if (pathName === "/unsubscribe") {
      navigate({
        pathname: "/unsubscribe",
        search:
          "?cid=" +
          consumerId +
          "&seid=" +
          surveyEmailId +
          "&type=" +
          type +
          "",
      });
    } else {
      if (surveyResponseData.surveyResponse) {
        //Consumer doesn't accept terms to share data.
        if (surveyResponseData.publicationStatus === 3) {
          if (surveyResponseData.surveyThankYouPage) {
            navigate({
              pathname: "/done",
              search:
                "?cid=" +
                consumerId +
                "&seid=" +
                surveyEmailId +
                "&type=" +
                type +
                "",
            });
          } else {
            if (
              surveyResponseData.starRating === 5 ||
              surveyResponseData.starRating === 4
            ) {
              navigate({
                pathname: "/thankyou",
                search:
                  "?cid=" +
                  consumerId +
                  "&seid=" +
                  surveyEmailId +
                  "&type=" +
                  type +
                  "",
              });
            } else {
              navigate({
                pathname: "/thanks",
                search:
                  "?cid=" +
                  consumerId +
                  "&seid=" +
                  surveyEmailId +
                  "&type=" +
                  type +
                  "",
              });
            }
          }
        } else {
          //Consumer accepted the terms
          if (surveyResponseData.surveyThirdPartySiteResponse) {
            if (surveyResponseData.surveyThankYouPage) {
              navigate({
                pathname: "/done",
                search:
                  "?cid=" +
                  consumerId +
                  "&seid=" +
                  surveyEmailId +
                  "&type=" +
                  type +
                  "",
              });
            } else {
              if (
                surveyResponseData.starRating === 5 ||
                surveyResponseData.starRating === 4
              ) {
                navigate({
                  pathname: "/thankyou",
                  search:
                    "?cid=" +
                    consumerId +
                    "&seid=" +
                    surveyEmailId +
                    "&type=" +
                    type +
                    "",
                });
              } else {
                navigate({
                  pathname: "/thanks",
                  search:
                    "?cid=" +
                    consumerId +
                    "&seid=" +
                    surveyEmailId +
                    "&type=" +
                    type +
                    "",
                });
              }
            }
          } else {
            if (
              surveyResponseData.starRating === 5 ||
              surveyResponseData.starRating === 4
            ) {
              if (
                surveyResponseData.surveyThirdPartySiteToPublish ===
                  "Edmunds" &&
                surveyResponseData.smartGmailSurvey === false
              ) {
                if (surveyResponseData.transactionType === "sales") {
                  navigate({
                    pathname: "/edmundsPublish",
                    search:
                      "?cid=" +
                      consumerId +
                      "&seid=" +
                      surveyEmailId +
                      "&type=" +
                      type +
                      "",
                  });
                } else {
                  //Go to positive thank you page.
                  navigate({
                    pathname: "/edmundsPublishSv",
                    search:
                      "?cid=" +
                      consumerId +
                      "&seid=" +
                      surveyEmailId +
                      "&type=" +
                      type +
                      "",
                  });
                }
              } else if (
                surveyResponseData.surveyThirdPartySiteToPublish ===
                  "Cars.com" &&
                surveyResponseData.smartGmailSurvey === false
              ) {
                navigate({
                  pathname: "/carsPublish",
                  search:
                    "?cid=" +
                    consumerId +
                    "&seid=" +
                    surveyEmailId +
                    "&type=" +
                    type +
                    "",
                });
              } else {
                if (surveyResponseData.surveyThankYouPage) {
                  navigate({
                    pathname: "/done",
                    search:
                      "?cid=" +
                      consumerId +
                      "&seid=" +
                      surveyEmailId +
                      "&type=" +
                      type +
                      "",
                  });
                } else {
                  navigate({
                    pathname: "/thankyou",
                    search:
                      "?cid=" +
                      consumerId +
                      "&seid=" +
                      surveyEmailId +
                      "&type=" +
                      type +
                      "",
                  });
                }
              }
            } else {
              navigate({
                pathname: "/thanks",
                search:
                  "?cid=" +
                  consumerId +
                  "&seid=" +
                  surveyEmailId +
                  "&type=" +
                  type +
                  "",
              });
            }
          }
        }
      } else {
        navigate({
          pathname: "/survey",
          search:
            "?cid=" +
            consumerId +
            "&seid=" +
            surveyEmailId +
            "&type=" +
            type +
            "",
        });
      }
    }
  }

  render() {
    return (
      <div className="review-surge-survey-container">
        <div className="App">
          <HeaderLogo
            urlData={{
              consumerId: this.state.cId,
              surveyEmailId: this.state.seId,
              type: this.state.type,
              osr: this.state.osr,
              clientId: this.state.clientId,
            }}
          />
          <Routes>
            <Route
              path="/"
              element={
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  <img src={loadingGif} alt="Loading"></img>
                </div>
              }
            ></Route>
            <Route path="/error" element={<SurveyNotFound />}></Route>
            <Route
              path="/survey"
              element={
                <SurveyMain
                  urlData={{
                    consumerId: this.state.cId,
                    surveyEmailId: this.state.seId,
                    type: this.state.type,
                    clientId: this.state.clientId,
                    thirdPartySiteToPublish: this.state.thirdPartySiteToPublish,
                    handler: this.handleChildChange,
                    handlerStarRating: this.handleOverallStarRatingChildChange,
                    handlerSurveyInfo: this.handleSurveyInfo,
                  }}
                />
              }
            ></Route>
            <Route
              path="/edmundsPublish"
              element={
                <EdmundsPublish
                  urlData={{
                    navigate: this.state.navigate,
                    consumerId: this.state.cId,
                    surveyEmailId: this.state.seId,
                    type: this.state.type,
                    clientId: this.state.clientId,
                    comments: this.state.comments,
                    customerFirstName: this.state.customerFirstName,
                    customerEmail: this.state.customerEmail,
                    ipAddress: this.state.ipAddress,
                    smartGmailSurvey: this.state.smartGmailSurvey,
                    handler: this.handleChildChange,
                    handlerSurveyInfo: this.handleSurveyInfo,
                  }}
                />
              }
            ></Route>
            <Route
              path="/edmundsPublishSv"
              element={
                <EdmundsPublishSv
                  urlData={{
                    navigate: this.state.navigate,
                    consumerId: this.state.cId,
                    surveyEmailId: this.state.seId,
                    type: this.state.type,
                    clientId: this.state.clientId,
                    comments: this.state.comments,
                    customerFirstName: this.state.customerFirstName,
                    customerEmail: this.state.customerEmail,
                    ipAddress: this.state.ipAddress,
                    smartGmailSurvey: this.state.smartGmailSurvey,
                    handler: this.handleChildChange,
                    handlerSurveyInfo: this.handleSurveyInfo,
                  }}
                />
              }
            ></Route>
            <Route
              path="/carsPublish"
              element={
                <CarsPublish
                  urlData={{
                    navigate: this.state.navigate,
                    consumerId: this.state.cId,
                    surveyEmailId: this.state.seId,
                    type: this.state.type,
                    clientId: this.state.clientId,
                    comments: this.state.comments,
                    customerFirstName: this.state.customerFirstName,
                    customerEmail: this.state.customerEmail,
                    ipAddress: this.state.ipAddress,
                    overallStarRating: this.state.overallStarRating,
                    smartGmailSurvey: this.state.smartGmailSurvey,
                    handler: this.handleChildChange,
                    handlerSurveyInfo: this.handleSurveyInfo,
                  }}
                />
              }
            ></Route>
            <Route
              path="/thankyou"
              element={
                <ThankYouPagePositive
                  urlData={{
                    navigate: this.state.navigate,
                    consumerId: this.state.cId,
                    surveyEmailId: this.state.seId,
                    type: this.state.type,
                    clientId: this.state.clientId,
                    comments: this.state.comments,
                    reviewSites: this.state.reviewSites,
                    customerFirstName: this.state.customerFirstName,
                    surveyThankYouPageText: this.state.surveyThankYouPageText,
                    smartGmailSurvey: this.state.smartGmailSurvey,
                    hasGoogle: this.state.hasGoogle,
                    handler: this.handleChildChange,
                    handlerSurveyInfo: this.handleSurveyInfo,
                  }}
                />
              }
            ></Route>
            <Route
              path="/thanks"
              element={
                <ThankYouPageNegative
                  urlData={{
                    navigate: this.state.navigate,
                    consumerId: this.state.cId,
                    surveyEmailId: this.state.seId,
                    type: this.state.type,
                    clientId: this.state.clientId,
                    reviewSitesNegative: this.state.reviewSitesNegative,
                    customerFirstName: this.state.customerFirstName,
                    comments: this.state.comments,
                    dealerName: this.state.dealerName,
                    surveyNegativeThankYouPageText:
                      this.state.surveyNegativeThankYouPageText,
                  }}
                />
              }
            ></Route>
            <Route
              path="/done"
              element={
                <DonePage
                  urlData={{
                    navigate: this.state.navigate,
                    consumerId: this.state.cId,
                    surveyEmailId: this.state.seId,
                    type: this.state.type,
                    clientId: this.state.clientId,
                  }}
                />
              }
            ></Route>
            <Route
              path="/unsubscribe"
              element={
                <Unsubscribe
                  urlData={{
                    consumerId: this.state.cId,
                    surveyEmailId: this.state.seId,
                    type: this.state.type,
                    clientId: this.state.clientId,
                  }}
                />
              }
            ></Route>
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
        <div className="separator"></div>
      </div>
    );
  }
}

export function AppWithRouter(props) {
  const navigate = useNavigate();
  return (
    <div>
      <div id="loaderSpinnerMain" className="loader"></div>
      <App
        urlData={{
          redirect: { navigate },
          consumerId: props.urlData.consumerId,
          surveyEmailId: props.urlData.surveyEmailId,
          type: props.urlData.type,
          pathName: props.urlData.pathName,
          osr: props.urlData.osr,
          clientId: props.urlData.clientId,
        }}
      ></App>
    </div>
  );
}
