import React, { Component } from "react";
import axios from "axios";
import Form from "react-bootstrap/Form";
import surveyPagination from "../assets/images/survey-pagination-2.png";
import downArrow from "../assets/images/down-arrow.png";
import { Link } from "react-router-dom";
import { isGuid } from "../utils/utils";
import $ from "jquery";

export default class ThankYouPagePositive extends Component {
  constructor(props) {
    super(props);

    window.scrollTo(0, 0);

    this.state = {
      cId: this.props.urlData.consumerId,
      seId: this.props.urlData.surveyEmailId,
      type: this.props.urlData.type,
      clientId: this.props.urlData.clientId,
      comments: this.props.urlData.comments,
      reviewSites: this.props.urlData.reviewSites,
      navigate: this.props.urlData.navigate,
      handler: this.props.urlData.handler,
      handlerSurveyInfo: this.props.urlData.handlerSurveyInfo,
      customerFirstName: this.props.urlData.customerFirstName,
      surveyThankYouPageText: this.props.urlData.surveyThankYouPageText,
      smartGmailSurvey: this.props.urlData.smartGmailSurvey,
      hasGoogle: this.props.urlData.hasGoogle,
      thankYouPageClick: false,
      openedSmartGmailSurvey: false,
    };

    this.clickThankYouPageSubmit = (e) => {
      e.preventDefault();
      this.ClickOnThankYouPage();
    };
  }

  componentDidMount() {
    window.onpopstate = () => {
      if (this.state.thankYouPageClick === false) {
        this.state.navigate(0);
      } else {
        this.setState({
          thankYouPageClick: false,
        });
      }
    };

    if (this.state.customerFirstName === "" || this.state.comments === "") {
      this.GetSurveyInfo();
    }

    if (this.state.reviewSites.length === 0) {
      this.GetDealerSites();
    }
  }

  async GetSurveyInfo() {
    const host = process.env.REACT_APP_API_BASE_URL;
    const consumerId = this.props.urlData.consumerId;
    const surveyEmailId = this.props.urlData.surveyEmailId;

    await axios
      .get(
        `${host}/surveyinfo?ConsumerId=${consumerId}&SurveyEmailId=${surveyEmailId}`,
        {
          headers: {
            "DAS-Guest-Subscription-Key":
              process.env.REACT_APP_API_SUBSCRIPTION_KEY, //the guest subscription key
          },
        }
      )
      .then((res) => {
        const surveyResponseData = res.data;

        this.setState({
          customerFirstName: surveyResponseData.customerFirstName,
          customerEmail: surveyResponseData.customerEmail,
          dealerName: surveyResponseData.dealerName,
          transactionType: surveyResponseData.transactionType,
          surveyThankYouPageText: surveyResponseData.surveyThankYouPageText,
          smartGmailSurvey: surveyResponseData.smartGmailSurvey,
        });

        this.state.handlerSurveyInfo(
          surveyResponseData.customerFirstName,
          surveyResponseData.customerEmail,
          surveyResponseData.dealerName,
          surveyResponseData.transactionType,
          surveyResponseData.surveyThirdPartySiteToPublish,
          surveyResponseData.surveyThankYouPageText,
          surveyResponseData.surveyNegativeThankYouPageText,
          surveyResponseData.smartGmailSurvey
        );

        this.GetSurveyResponseInfo();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async GetSurveyResponseInfo() {
    const host = process.env.REACT_APP_API_BASE_URL;
    const consumerId = this.props.urlData.consumerId;
    const surveyEmailId = this.props.urlData.surveyEmailId;
    const clientId = this.props.urlData.clientId;

    await axios
      .get(
        `${host}/surveyresponseinfo?ConsumerId=${consumerId}&SurveyEmailId=${surveyEmailId}&ClientId=${clientId}`,
        {
          headers: {
            "DAS-Guest-Subscription-Key":
              process.env.REACT_APP_API_SUBSCRIPTION_KEY, //the guest subscription key
          },
        }
      )
      .then((res) => {
        const surveyResponseInfoData = res.data;

        this.setState({
          comments: surveyResponseInfoData.surveyResponseText,
        });

        this.state.handler(surveyResponseInfoData.surveyResponseText);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async GetDealerSites() {
    const host = process.env.REACT_APP_API_BASE_URL;
    const consumerId = this.props.urlData.consumerId;
    const surveyEmailId = this.props.urlData.surveyEmailId;
    const clientId = this.props.urlData.clientId;

    if (isGuid(consumerId) && isGuid(surveyEmailId)) {
      const headers = {
        "DAS-Guest-Subscription-Key":
          process.env.REACT_APP_API_SUBSCRIPTION_KEY,
      };

      var redirectSite = "";
      var windowReference = null;

      if (this.state.smartGmailSurvey && this.state.hasGoogle) {
        windowReference = window.open();
      }

      await axios
        .get(
          `${host}/thankyoupagesites?ConsumerId=${consumerId}&SurveyEmailId=${surveyEmailId}&ClientId=${clientId}`,
          { headers }
        )
        .then((res) => {
          const dealerData = res.data;

          this.setState({
            reviewSites: dealerData,
          });

          //Open Google
          if (this.state.smartGmailSurvey) {
            if (Array.isArray(dealerData) && dealerData.length) {
              const googlesite = dealerData.filter(function (el) {
                return el.name === "Google";
              });

              if (googlesite.length > 0) {
                redirectSite = googlesite[0].redirectUrl;
              }

              if (this.state.smartGmailSurvey && redirectSite !== "") {
                if (windowReference !== null) {
                  windowReference.location.href = redirectSite;
                } else {
                  //var windowReferenceTrigger = window.open();
                  //windowReferenceTrigger.location = redirectSite;
                }
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  async ClickOnReviewSite(reviewSiteId, redirectUrl) {
    const host = process.env.REACT_APP_API_BASE_URL;
    const consumerId = this.props.urlData.consumerId;
    const surveyEmailId = this.props.urlData.surveyEmailId;
    const clientId = this.props.urlData.clientId;

    if (isGuid(consumerId) && isGuid(surveyEmailId)) {
      this.setState({
        thankYouPageClick: true,
      });

      const body = {};
      const headers = {
        "DAS-Guest-Subscription-Key":
          process.env.REACT_APP_API_SUBSCRIPTION_KEY,
      };

      var redirectSite = "";
      var windowReference = window.open();

      await axios
        .post(
          `${host}/clickonreviewsite?ConsumerId=${consumerId}&SurveyEmailId=${surveyEmailId}&ReviewSiteId=${reviewSiteId}&ClientId=${clientId}`,
          body,
          { headers }
        )
        .then((res) => {
          redirectSite = redirectUrl;
        })
        .catch((error) => {
          console.log(error);
        });

      if (redirectSite !== "") {
        windowReference.location = redirectSite;
      }
    }
  }

  async ClickOnThankYouPage() {
    $("#loaderSpinnerTYPositive").show();
    const host = process.env.REACT_APP_API_BASE_URL;
    const consumerId = this.props.urlData.consumerId;
    const surveyEmailId = this.props.urlData.surveyEmailId;
    const clientId = this.props.urlData.clientId;

    if (clientId === null) {
      if (isGuid(consumerId) && isGuid(surveyEmailId)) {
        const body = {};
        const headers = {
          "DAS-Guest-Subscription-Key":
            process.env.REACT_APP_API_SUBSCRIPTION_KEY,
        };

        await axios
          .post(
            `${host}/clickthankyoupage?ConsumerId=${consumerId}&SurveyEmailId=${surveyEmailId}`,
            body,
            { headers }
          )
          .then((res) => {
            this.state.navigate({
              pathname: "/done",
              search:
                "?cid=" +
                this.state.cId +
                "&seid=" +
                this.state.seId +
                "&type=" +
                this.state.type +
                "",
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      this.state.navigate({
        pathname: "/done",
        search:
          "?cid=" +
          this.state.cId +
          "&seid=" +
          this.state.seId +
          "&type=" +
          this.state.type +
          "",
      });
    }

    $("#loaderSpinnerTYPositive").hide();
  }

  render() {
    return (
      <div className="survey-menu-container">
        <div id="loaderSpinnerTYPositive" className="loader"></div>
        <table width="100%" height="50px">
          <tbody>
            <tr>
              <td>
                <p className="text-question-bold">Thank You!</p>
              </td>
              <td>
                <span className="survey-pagination">
                  <img
                    src={surveyPagination}
                    alt="Progress bar.  Page 2 of 3."
                  ></img>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="survey-main-description">
          <span>
            <p>{this.state.customerFirstName},</p>
            <p style={{ whiteSpace: "pre-line" }}>
              {this.state.surveyThankYouPageText}
            </p>
          </span>
          <div className="thank-you-page-positive-feedback-div">
            <p>Copy your feedback below:</p>
            <div className="improve-text-div">
              <Form.Group className="mb-3" controlId="txtThankYouFeedback">
                <Form.Control
                  value={this.state.comments}
                  readOnly
                  size="sm"
                  as="textarea"
                  rows={4}
                  style={{
                    fontSize: "13px",
                  }}
                />
              </Form.Group>
            </div>
          </div>
          <div className="thankyoupagesites-div">
            <table
              className="table-reviewsites"
              cellPadding="0px"
              cellSpacing="0px"
              border="0px"
            >
              <tbody>
                <tr>
                  {this.state.reviewSites.map((reviewSite) => {
                    return (
                      <td key={Math.random()}>
                        <table
                          className="table-reviewsiteselement"
                          cellPadding="0px"
                          cellSpacing="0px"
                          border="0px"
                        >
                          <tbody>
                            <tr>
                              <td className="thank-you-page-site-header">
                                Share your review, <br></br> click below
                              </td>
                            </tr>
                            <tr>
                              <td className="thank-you-page-site-arrow">
                                <img
                                  src={downArrow}
                                  border="0"
                                  alt="Click below to share your review."
                                ></img>
                              </td>
                            </tr>
                            <tr>
                              <td className="thank-you-page-site-reviewsite">
                                <a
                                  rel="noopener noreferrer"
                                  href="#/"
                                  onClick={() =>
                                    this.ClickOnReviewSite(
                                      reviewSite.reviewSiteId,
                                      reviewSite.redirectUrl
                                    )
                                  }
                                >
                                  <img
                                    src={reviewSite.siteImgUrl}
                                    border="0"
                                    alt={
                                      "Click below to share your review on " +
                                      reviewSite.reviewSiteName
                                    }
                                  ></img>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          style={{
            textAlign: "center",
            marginTop: "30px",
            marginBottom: "10px",
          }}
        >
          <Link
            className="btn btn-primary"
            onClick={this.clickThankYouPageSubmit}
          >
            Done
          </Link>
        </div>
      </div>
    );
  }
}
