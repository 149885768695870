import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import $ from "jquery";

const SubmitSurvey = (formValues) => {
  let navigate = useNavigate();

  const surveySubmitted = async (e) => {
    e.preventDefault();
    const host = process.env.REACT_APP_API_BASE_URL;
    const consumerId = formValues.props.cId;
    const surveyEmailId = formValues.props.seId;
    const type = formValues.props.type;
    const thirdPartySiteToPublish = formValues.props.thirdPartySiteToPublish;
    const transactionType = formValues.props.transactionType;
    const starRating = formValues.props.starRating;
    const npsScore = formValues.props.npsScore;
    const experienceFeedback = formValues.props.experienceFeedback;
    const improveFeedback = formValues.props.improveFeedback;
    const publicationStatus = formValues.props.chkTerms;
    const clientId = formValues.props.clientId;
    const smartGmailSurvey = formValues.props.smartGmailSurvey;

    if (
      consumerId !== null &&
      surveyEmailId !== null &&
      type !== null &&
      type !== "" &&
      starRating !== "0" &&
      npsScore !== "" &&
      experienceFeedback.trim() !== ""
    ) {
      //Submit
      $("#starRatingSelector").removeClass("is-invalid");
      $("#npsScoreDropDown").removeClass("is-invalid");
      $("#txtExperience").removeClass("is-invalid");

      $("#loaderSpinnerSubmit").show();

      //Request body
      const body = {
        consumerId: consumerId,
        surveyEmailId: surveyEmailId,
        npsScore: npsScore,
        rating: starRating,
        comments: experienceFeedback,
        feedback: improveFeedback,
        publicationStatus: publicationStatus,
        surveyAnsweredBy: type,
      };

      //this means that is not a preview.
      if (clientId === null) {
        //Submit request.
        const request = new XMLHttpRequest();
        request.open("POST", `${host}/surveyresponse`, false);
        request.setRequestHeader(
          "DAS-Guest-Subscription-Key",
          process.env.REACT_APP_API_SUBSCRIPTION_KEY
        );
        request.setRequestHeader("Content-Type", "application/json");
        request.send(JSON.stringify(body));

        if (request.status === 200) {
          RedirectToPage(
            consumerId,
            surveyEmailId,
            type,
            starRating,
            transactionType,
            thirdPartySiteToPublish,
            publicationStatus,
            smartGmailSurvey
          );
        }
      } else {
        RedirectToPage(
          consumerId,
          surveyEmailId,
          type,
          starRating,
          transactionType,
          thirdPartySiteToPublish,
          publicationStatus,
          smartGmailSurvey
        );
      }

      $("#loaderSpinnerSubmit").hide();
    } else {
      //Enable button again if throws a validation.
      $("#btnSubmit").removeClass("disabled");

      //Validate
      if (starRating === 0) {
        $("#starRatingSelector").addClass("is-invalid");
      } else {
        $("#starRatingSelector").removeClass("is-invalid");
      }
      if (npsScore === "") {
        $("#npsScoreDropDown").addClass("is-invalid");
      } else {
        $("#npsScoreDropDown").removeClass("is-invalid");
      }
      if (experienceFeedback.trim() === "") {
        $("#txtExperience").addClass("is-invalid");
      } else {
        $("#txtExperience").removeClass("is-invalid");
      }
    }
  };

  const RedirectToPage = (
    consumerId,
    surveyEmailId,
    type,
    starRating,
    transactionType,
    thirdPartySiteToPublish,
    publicationStatusChk,
    smartGmailSurvey
  ) => {
    if (
      publicationStatusChk &&
      (starRating === 5 || starRating === 4) &&
      smartGmailSurvey === false
    ) {
      if (thirdPartySiteToPublish === "Edmunds") {
        if (transactionType === "sales") {
          //Go to edmunds publish page sales
          navigate({
            pathname: "/edmundsPublish",
            search:
              "?cid=" +
              consumerId +
              "&seid=" +
              surveyEmailId +
              "&type=" +
              type +
              "",
          });
        } else {
          //Go to edmunds publish page service
          navigate({
            pathname: "/edmundsPublishSv",
            search:
              "?cid=" +
              consumerId +
              "&seid=" +
              surveyEmailId +
              "&type=" +
              type +
              "",
          });
        }
      } else if (thirdPartySiteToPublish === "Cars.com") {
        navigate({
          pathname: "/carsPublish",
          search:
            "?cid=" +
            consumerId +
            "&seid=" +
            surveyEmailId +
            "&type=" +
            type +
            "",
        });
      } else {
        //Go to positive thank you page.
        navigate({
          pathname: "/thankyou",
          search:
            "?cid=" +
            consumerId +
            "&seid=" +
            surveyEmailId +
            "&type=" +
            type +
            "",
        });
      }
    } else {
      if (starRating === 5 || starRating === 4) {
        //Go to positive thank you page.
        navigate({
          pathname: "/thankyou",
          search:
            "?cid=" +
            consumerId +
            "&seid=" +
            surveyEmailId +
            "&type=" +
            type +
            "",
        });
      } else {
        //Go to negative thank you page.
        navigate({
          pathname: "/thanks",
          search:
            "?cid=" +
            consumerId +
            "&seid=" +
            surveyEmailId +
            "&type=" +
            type +
            "",
        });
      }
    }
  };

  return (
    <div
      style={{
        textAlign: "center",
        marginTop: "5px",
        marginBottom: "10px",
      }}
    >
      <Link
        id="btnSubmit"
        className="btn btn-primary"
        preventScrollReset={false}
        onClick={(e) => {
          $("#btnSubmit").addClass("disabled");
          surveySubmitted(e);
        }}
      >
        Submit
      </Link>
      <div id="loaderSpinnerSubmit" className="loader"></div>
    </div>
  );
};

export default SubmitSurvey;
