import React, { Component } from "react";
import $ from "jquery";
import axios from "axios";
import surveyPagination from "../assets/images/survey-pagination-2-1.png";
import carsLogo from "../assets/images/cars-logo.png";
import { Rating } from "react-simple-star-rating";
import { Link } from "react-router-dom";
import { isGuid } from "../utils/utils";
import Form from "react-bootstrap/Form";

export default class CarsPublish extends Component {
  constructor(props) {
    super(props);

    window.scrollTo(0, 0);

    this.state = {
      cId: this.props.urlData.consumerId,
      seId: this.props.urlData.surveyEmailId,
      type: this.props.urlData.type,
      clientId: this.props.urlData.clientId,
      ipAddress: this.props.urlData.ipAddress,
      navigate: this.props.urlData.navigate,
      customerFirstName: this.props.urlData.customerFirstName,
      customerEmail: this.props.urlData.customerEmail,
      starRating: this.props.urlData.overallStarRating,
      comments: this.props.urlData.comments,
      handler: this.props.urlData.handler,
      handlerSurveyInfo: this.props.urlData.handlerSurveyInfo,
      smartGmailSurvey: this.props.urlData.smartGmailSurvey,
      customerServiceRating: 5,
      buyingProcessRating: 5,
      qualityRepairsRating: 5,
      facilitiesRating: 5,
      chkCustomerService: false,
      chkBuyingProcess: false,
      chkQualityRepairs: false,
      chkFacilities: false,
      title: "",
      cityState: "",
    };

    this.handleCarsTitleChange = (e) => {
      const carsTitle = e.target.value;
      this.setState({
        title: carsTitle,
      });
    };

    this.handleCarsCityChange = (e) => {
      const carsCity = e.target.value;
      this.setState({
        cityState: carsCity,
      });
    };

    this.handleCarsCommentsChange = (e) => {
      const carsComments = e.target.value;
      this.setState({
        comments: carsComments,
      });
    };

    this.handleCustomerServiceRating = (rate) => {
      this.setState({
        customerServiceRating: rate,
      });
    };

    this.handleBuyingProcessRating = (rate) => {
      this.setState({
        buyingProcessRating: rate,
      });
    };

    this.handleQualityRepairsRating = (rate) => {
      this.setState({
        qualityRepairsRating: rate,
      });
    };

    this.handleFacilitiesRating = (rate) => {
      this.setState({
        facilitiesRating: rate,
      });
    };

    this.handleCustomerServiceChangeChk = (evt) => {
      this.setState({
        chkCustomerService: evt.target.checked,
      });
    };

    this.handleBuyingProcessChangeChk = (evt) => {
      this.setState({
        chkBuyingProcess: evt.target.checked,
      });
    };

    this.handleQualityRepairsChangeChk = (evt) => {
      this.setState({
        chkQualityRepairs: evt.target.checked,
      });
    };

    this.handleFacilitiesChangeChk = (evt) => {
      this.setState({
        chkFacilities: evt.target.checked,
      });
    };

    this.clickCarsSubmit = (e) => {
      e.preventDefault();
      this.SubmitCars();
    };

    this.clickCarsSkip = (e) => {
      e.preventDefault();
      this.SubmitCarsSkip();
    };
  }

  componentDidMount() {
    window.onpopstate = () => {
      this.state.navigate(0);
    };

    if (
      this.state.customerFirstName === "" ||
      this.state.customerEmail === "" ||
      this.state.comments === ""
    ) {
      this.GetSurveyInfo();
      this.GetGeoLocationData();
    }
  }

  async GetGeoLocationData() {
    /*await axios
      .get("https://geolocation-db.com/json/")
      .then((res) => {
        const geolocationData = res.data;
        this.setState({ ipAddress: geolocationData.IPv4 });
      })
      .catch((error) => {
        console.log(error);
      }); */
  }

  async GetSurveyInfo() {
    const host = process.env.REACT_APP_API_BASE_URL;
    const consumerId = this.props.urlData.consumerId;
    const surveyEmailId = this.props.urlData.surveyEmailId;

    await axios
      .get(
        `${host}/surveyinfo?ConsumerId=${consumerId}&SurveyEmailId=${surveyEmailId}`,
        {
          headers: {
            "DAS-Guest-Subscription-Key":
              process.env.REACT_APP_API_SUBSCRIPTION_KEY, //the guest subscription key
          },
        }
      )
      .then((res) => {
        const surveyResponseData = res.data;

        this.setState({
          customerFirstName: surveyResponseData.customerFirstName,
          customerEmail: surveyResponseData.customerEmail,
          dealerName: surveyResponseData.dealerName,
          transactionType: surveyResponseData.transactionType,
          smartGmailSurvey: surveyResponseData.smartGmailSurvey,
        });

        this.state.handlerSurveyInfo(
          surveyResponseData.customerFirstName,
          surveyResponseData.customerEmail,
          surveyResponseData.dealerName,
          surveyResponseData.transactionType,
          surveyResponseData.surveyThirdPartySiteToPublish,
          surveyResponseData.surveyThankYouPageText,
          surveyResponseData.surveyNegativeThankYouPageText,
          surveyResponseData.smartGmailSurvey
        );

        this.GetSurveyResponseInfo();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async GetSurveyResponseInfo() {
    const host = process.env.REACT_APP_API_BASE_URL;
    const consumerId = this.props.urlData.consumerId;
    const surveyEmailId = this.props.urlData.surveyEmailId;

    await axios
      .get(
        `${host}/surveyresponseinfo?ConsumerId=${consumerId}&SurveyEmailId=${surveyEmailId}`,
        {
          headers: {
            "DAS-Guest-Subscription-Key":
              process.env.REACT_APP_API_SUBSCRIPTION_KEY, //the guest subscription key
          },
        }
      )
      .then((res) => {
        const surveyResponseInfoData = res.data;

        this.setState({
          comments: surveyResponseInfoData.surveyResponseText,
        });

        this.state.handler(surveyResponseInfoData.surveyResponseText);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async SubmitCars() {
    $("#loaderSpinnerCars").show();
    const host = process.env.REACT_APP_API_BASE_URL;
    const consumerId = this.props.urlData.consumerId;
    const surveyEmailId = this.props.urlData.surveyEmailId;
    const type = this.props.urlData.type;
    const starRating = this.state.starRating;
    const reviewTitle = this.state.title;
    const cityState = this.state.cityState;
    const review = this.state.comments;
    const customerServiceRating = this.state.customerServiceRating;
    const buyingProcessRating = this.state.buyingProcessRating;
    const qualityRepairsRating = this.state.qualityRepairsRating;
    const facilitiesRating = this.state.facilitiesRating;
    const chkCustomerService = this.state.chkCustomerService;
    const chkBuyingProcess = this.state.chkBuyingProcess;
    const chkQualityRepairs = this.state.chkQualityRepairs;
    const chkFacilities = this.state.chkFacilities;
    const clientId = this.props.urlData.clientId;

    if (clientId === null) {
      if (isGuid(consumerId) && isGuid(surveyEmailId)) {
        if (
          consumerId !== null &&
          surveyEmailId !== null &&
          type !== null &&
          type !== "" &&
          starRating !== 0 &&
          reviewTitle !== "" &&
          review !== "" &&
          cityState !== ""
        ) {
          //Remove classes
          $("#txtReviewTitle").removeClass("is-invalid");
          $("#txtYourReview").removeClass("is-invalid");
          $("#txtEmailAddress").removeClass("is-invalid");
          $("#txtReviewDisplayName").removeClass("is-invalid");

          //Request body
          const body = {
            consumerId: consumerId,
            surveyEmailId: surveyEmailId,
            reviewTitle: this.state.title,
            review: this.state.comments,
            locationDescription: cityState,
            customerServiceRating: "" + customerServiceRating,
            buyingProcessRating: "" + buyingProcessRating,
            qualityRepairsRating: "" + qualityRepairsRating,
            facilities: "" + facilitiesRating,
            chkcustomerServiceRating: chkCustomerService,
            chkBuyingProcessRating: chkBuyingProcess,
            chkQualityRepairsRating: chkQualityRepairs,
            chkFacilities: chkFacilities,
            starRating: this.state.starRating,
            ipAddress: this.state.ipAddress,
            surveyOpened: type,
          };
          const headers = {
            "DAS-Guest-Subscription-Key":
              process.env.REACT_APP_API_SUBSCRIPTION_KEY,
          };

          await axios
            .post(`${host}/publishcars`, body, { headers })
            .then((res) => {
              this.state.navigate({
                pathname: "/thankyou",
                search:
                  "?cid=" +
                  this.state.cId +
                  "&seid=" +
                  this.state.seId +
                  "&type=" +
                  this.state.type +
                  "",
              });
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          //Validate
          if (reviewTitle === "") {
            $("#txtReviewTitle").addClass("is-invalid");
          } else {
            $("#txtReviewTitle").removeClass("is-invalid");
          }
          if (review === "") {
            $("#txtYourReview").addClass("is-invalid");
          } else {
            $("#txtYourReview").removeClass("is-invalid");
          }
          if (cityState === "") {
            $("#txtCityState").addClass("is-invalid");
          } else {
            $("#txtCityState").removeClass("is-invalid");
          }
        }
      }
    } else {
      this.state.navigate({
        pathname: "/thankyou",
        search:
          "?cid=" +
          this.state.cId +
          "&seid=" +
          this.state.seId +
          "&type=" +
          this.state.type +
          "",
      });
    }

    $("#loaderSpinnerCars").hide();
  }

  async SubmitCarsSkip() {
    $("#loaderSpinnerCars").show();
    const host = process.env.REACT_APP_API_BASE_URL;
    const consumerId = this.props.urlData.consumerId;
    const surveyEmailId = this.props.urlData.surveyEmailId;
    const clientId = this.props.urlData.clientId;

    if (clientId === null) {
      if (isGuid(consumerId) && isGuid(surveyEmailId)) {
        const body = {};
        const headers = {
          "DAS-Guest-Subscription-Key":
            process.env.REACT_APP_API_SUBSCRIPTION_KEY,
        };

        await axios
          .post(
            `${host}/publishcarsskip?ConsumerId=${consumerId}&SurveyEmailId=${surveyEmailId}`,
            body,
            { headers }
          )
          .then((res) => {
            this.state.navigate({
              pathname: "/thankyou",
              search:
                "?cid=" +
                this.state.cId +
                "&seid=" +
                this.state.seId +
                "&type=" +
                this.state.type +
                "",
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      this.state.navigate({
        pathname: "/thankyou",
        search:
          "?cid=" +
          this.state.cId +
          "&seid=" +
          this.state.seId +
          "&type=" +
          this.state.type +
          "",
      });
    }

    $("#loaderSpinnerCars").hide();
  }

  render() {
    return (
      <div className="survey-menu-container">
        <div id="loaderSpinnerCars" className="loader"></div>
        <table width="100%">
          <tbody>
            <tr>
              <td
                style={{
                  verticalAlign: "bottom",
                }}
              >
                <img src={carsLogo} alt="Cars.com Form Page"></img>
              </td>
              <td>
                <span className="survey-pagination">
                  <img
                    src={surveyPagination}
                    alt="Progress bar.  Page 2 of 3."
                  ></img>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <hr
          style={{
            height: "2px",
            backgroundColor: "darkblue",
            border: "none",
          }}
        ></hr>
        <div className="survey-main-description">
          <div
            style={{
              marginBottom: "15px",
            }}
          >
            <table width="100%">
              <tbody>
                <tr>
                  <td
                    width="70%"
                    style={{
                      verticalAlign: "top",
                      textAlign: "justify",
                    }}
                  >
                    <span>
                      <p>
                        Thank you for taking a minute to share your experience
                        with us. Your feedback enables us to continue making
                        improvements to the customer experience. We would like
                        your permission to share your experience with others by
                        posting your review below on Cars.com.
                      </p>
                    </span>
                  </td>
                  <td
                    width="30%"
                    style={{
                      verticalAlign: "top",
                      textAlign: "center",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          fontWeight: "bold",
                          verticalAlign: "bottom",
                        }}
                      >
                        Overall
                      </div>
                      <div className="rating-star-cars-div">
                        <Rating
                          id="starRatingSelector"
                          initialValue="5"
                          size="23"
                          readonly="true"
                        />
                      </div>
                      <div>
                        <table width="100%">
                          <tbody>
                            <tr>
                              <td width="57%">|</td>
                              <td width="25%">|</td>
                              <td width="15%"></td>
                            </tr>
                            <tr>
                              <td width="57%">Poor</td>
                              <td width="25%">Excellent</td>
                              <td width="15%"></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="control-group">
          <div className="cars-question-div">
            <Form.Group className="mb-3" controlId="txtReviewTitle">
              <Form.Label
                style={{
                  fontWeight: "bold",
                  fontSize: "13px",
                }}
              >
                Review Title: <font color="red">*</font>&nbsp;
              </Form.Label>
              <Form.Control
                style={{
                  fontWeight: "normal",
                  fontSize: "13px",
                }}
                placeholder="Example: Great Service, clean facilities"
                maxLength={75}
                onChange={this.handleCarsTitleChange}
              />
            </Form.Group>
          </div>
        </div>
        <div className="control-group">
          <div className="cars-question-div">
            <Form.Group className="mb-3" controlId="txtCityState">
              <Form.Label
                style={{
                  fontWeight: "bold",
                  fontSize: "13px",
                }}
              >
                City, State: <font color="red">*</font>&nbsp;
              </Form.Label>
              <Form.Control
                style={{
                  fontWeight: "normal",
                  fontSize: "13px",
                }}
                placeholder="Example: Chicago, IL"
                maxLength={75}
                onChange={this.handleCarsCityChange}
              />
              <Form.Text
                className="text-muted"
                style={{
                  fontStyle: "italic",
                  marginLeft: "5px",
                }}
              >
                We'll show the review as being from here.
              </Form.Text>
            </Form.Group>
          </div>
        </div>
        <div className="control-group">
          <div className="experience-text-div">
            <Form.Group className="mb-3" controlId="txtYourReview">
              <Form.Label
                style={{
                  fontWeight: "bold",
                  fontSize: "13px",
                }}
              >
                Your Review: <font color="red">*</font>&nbsp;
              </Form.Label>
              <Form.Control
                value={this.state.comments}
                size="sm"
                as="textarea"
                rows={3}
                style={{
                  fontWeight: "normal",
                  fontSize: "13px",
                }}
                onChange={this.handleCarsCommentsChange}
              />
            </Form.Group>
          </div>
        </div>
        <div>
          <table width="100%">
            <tbody>
              <tr valign="center" height="40px">
                <td width="25%">
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "13px",
                      marginBottom: "0",
                    }}
                  >
                    Customer Service&nbsp;
                  </p>
                </td>
                <td width="25%">
                  {" "}
                  <div>
                    <Rating
                      id="starRatingSelectorCSRating"
                      onClick={this.handleCustomerServiceRating}
                      initialValue="5"
                      size="25"
                    />
                  </div>
                </td>
                <td width="3%">
                  {" "}
                  <Form.Check
                    type="checkbox"
                    id="customer-service-checkbox"
                    defaultChecked={this.state.chkCustomerService}
                    onChange={this.handleCustomerServiceChangeChk}
                  />
                </td>
                <td width="65%">
                  {"Not Applicable"}
                  <font color="red">*</font>&nbsp;
                </td>
              </tr>
              <tr valign="center" height="40px">
                <td width="25%">
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "13px",
                      marginBottom: "0",
                    }}
                  >
                    Buying Process&nbsp;
                  </p>
                </td>
                <td width="25%">
                  {" "}
                  <div>
                    <Rating
                      id="starRatingSelectorBPRating"
                      onClick={this.handleBuyingProcessRating}
                      initialValue="5"
                      size="25"
                    />
                  </div>
                </td>
                <td width="3%">
                  {" "}
                  <Form.Check
                    type="checkbox"
                    id="buying-process-checkbox"
                    defaultChecked={this.state.chkBuyingProcess}
                    onChange={this.handleBuyingProcessChangeChk}
                  />
                </td>
                <td width="65%">
                  {"Not Applicable"}
                  <font color="red">*</font>&nbsp;
                </td>
              </tr>
              <tr valign="center" height="40px">
                <td width="25%">
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "13px",
                      marginBottom: "0",
                    }}
                  >
                    Quality of Repairs&nbsp;
                  </p>
                </td>
                <td width="25%">
                  {" "}
                  <div>
                    <Rating
                      id="starRatingSelectorQRRating"
                      onClick={this.handleQualityRepairsRating}
                      initialValue="5"
                      size="25"
                    />
                  </div>
                </td>
                <td width="3%">
                  {" "}
                  <Form.Check
                    type="checkbox"
                    id="quality-repairs-checkbox"
                    defaultChecked={this.state.chkQualityRepairs}
                    onChange={this.handleQualityRepairsChangeChk}
                  />
                </td>
                <td width="65%">
                  {"Not Applicable"}
                  <font color="red">*</font>&nbsp;
                </td>
              </tr>
              <tr valign="center" height="40px">
                <td width="25%">
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "13px",
                      marginBottom: "0",
                    }}
                  >
                    Facilities&nbsp;
                  </p>
                </td>
                <td width="25%">
                  {" "}
                  <div>
                    <Rating
                      id="starRatingSelectorFacilitiesRating"
                      onClick={this.handleFacilitiesRating}
                      initialValue="5"
                      size="25"
                    />
                  </div>
                </td>
                <td width="3%">
                  {" "}
                  <Form.Check
                    type="checkbox"
                    id="facilities-checkbox"
                    defaultChecked={this.state.chkFacilities}
                    onChange={this.handleFacilitiesChangeChk}
                  />
                </td>
                <td width="65%">
                  {"Not Applicable"}
                  <font color="red">*</font>&nbsp;
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          style={{
            textAlign: "left",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <Link className="btn btn-primary" onClick={this.clickCarsSubmit}>
            Submit
          </Link>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Link className="btn btn-danger" onClick={this.clickCarsSkip}>
            Skip
          </Link>
        </div>
        <hr
          style={{
            height: "1px",
            backgroundColor: "darkblue",
            border: "none",
            margin: "5px",
          }}
        ></hr>
        <div>
          <span>
            <p
              style={{
                textAlign: "left",
                fontSize: "11px",
                paddingLeft: "10px",
                marginBottom: "0px",
              }}
            >
              By clicking "Submit", your review will be posted on Cars.com.
              Please note that your review may take up to 72 hours to appear on
              Cars.com.
            </p>
          </span>
        </div>
        <div
          style={{
            textAlign: "right",
            verticalAlign: "top",
          }}
        >
          <span>
            <font color="red">*</font>&nbsp;Required
          </span>
        </div>
      </div>
    );
  }
}
